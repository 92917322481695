<template>
  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>信息</el-breadcrumb-item>
      <el-breadcrumb-item>应用</el-breadcrumb-item>
    </el-breadcrumb>

    <br />

    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>
    </el-row>


    <el-dialog title="对象配置" :visible.sync="dialogFormAdConfig" >
      <appAdType :row="appTemp"  />
    </el-dialog>

<!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="客户" prop="customerId">
          <el-select v-model="temp.customerId" v-bind:disabled="dialogStatus === 'update'"  style="width:500px"   placeholder="客户">
            <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.businessName+'-'+item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="应用名称" prop="name">
          <el-input v-model="temp.name" />
        </el-form-item>

        <el-form-item label="应用包名" prop="pkg">
          <el-input v-model="temp.pkg" />
        </el-form-item>

        <el-form-item label="应用版本号" prop="versionName">
          <el-input v-model="temp.versionName" />
        </el-form-item>

        <el-form-item label="应用标签" prop="type">
          <el-input v-model="temp.type" />
        </el-form-item>

        <el-form-item label="应用属性" prop="attribute">
          <el-select v-model="temp.attribute" placeholder="请选择">
            <el-option
                v-for="item in attributeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table
        ref="singleTable"
        :data="tableData"
        border
        fit
        style="width: 100%;height: 100%"
       >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>
      <el-table-column prop="id" label="应用ID" min-width="50" />
      <el-table-column
          prop="customerName"
          label="客户"
          min-width="50">
      </el-table-column>

      <el-table-column prop="name" label="应用名称" min-width="50" />

      <el-table-column prop="pkg" label="应用包名" min-width="50" />

      <el-table-column prop="versionName" label="应用版本号" min-width="30" />

      <el-table-column prop="type" label="应用标签" min-width="50" />

      <el-table-column prop="attribute" label="应用属性 " min-width="50" >

        <template slot-scope="scope">
          {{scope.row.attribute === 2? '资金盘类型项目':'常规类型项目'}}
        </template>

      </el-table-column>

      <el-table-column label="广告对象密钥" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleAdSet(scope.$index, scope.row)">配置</el-button>

        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
<!--          <el-button-->
<!--              size="mini"-->
<!--              type="danger"-->
<!--              @click="handleDelete(scope.$index, scope.row)">删除</el-button>-->
        </template>
      </el-table-column>

    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />
    </div>
  </div>
</template>

<script>
import { listApi,addApi,updateApi,delApi } from '@/api/apps'
import { listAllApi } from '@/api/customers'

import appAdType from "@/components/appAdType";


export default {
  name: "apps",
  components: { appAdType },
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        customerId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        pkg: [{ required: true, message: '不能为空', trigger: 'blur' }],
        versionName: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },
      temp: {
        id: undefined,
        customerId: '',
        name: '',
        pkg: '',
        versionName: '',
        type: '',
        attribute: '',

      },
      appTemp:{

      },
      dialogFormAdConfig:false,
      tableData: [],
      customerOptions:[],
      attributeOptions:[{
        id: 1,
        label: '常规类型项目'
      },{
        id: 2,
        label: '资金盘类型项目'
      }],

    }
  },
  created() {
    this.getList();
    this.getInitOptions();
  },
  methods:{
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
        this.customerOptions = response.body.records
      })
    },
    getList() {
      this.listLoading = true
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },


    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log(this.temp);
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        customerId: '',
        name: '',
        pkg: '',
        versionName: '',
        type: '',
        attribute: '',
      }
    },
    handleContant(index, row) {
      console.info(index,row);
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleAdSet(index, row){
      console.log(index, row);
      this.dialogFormAdConfig = true
      this.appTemp = row;

    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    }
  }
}

</script>

<style scoped>


</style>
