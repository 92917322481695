<template>
  <div>

    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>
    </el-row>
    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="广告对象" prop="adObjectName">

          <el-select v-model="temp.adObjectName"  placeholder="广告对象">
            <el-option
                v-for="item in adOptions"
                :key="item.name"
                :label="item.nameZh"
                :value="item.name">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="媒体应用id" prop="mappid">
          <el-input v-model="temp.mappid" />
        </el-form-item>

        <el-form-item label="adKey" prop="adKey">
          <el-input v-model="temp.adKey" />
        </el-form-item>

        <el-form-item label="adSecret" prop="adSecret">
          <el-input v-model="temp.adSecret" />
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

      <el-table-column fixed fit align="center" label="#" type="index" min-width="10" />

      <el-table-column prop="adObjectNameZh" label="广告对象" min-width="30" />

      <el-table-column prop="mappid" label="媒体应用id" min-width="30" />

      <el-table-column prop="adKey" label="adKey" min-width="50" />

      <el-table-column prop="adSecret" label="adSecret" min-width="50" />

      <el-table-column label="操作" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
<!--    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />-->

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />



  </div>
</template>

<script>
import { listApi,addApi,updateApi,delApi } from '@/api/appAdsType'

import { adslistApi } from '@/api/adObjects'
export default {
  name: "adsDetailConfig",
  props: ['row'],
  components: { },
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        adId: '',
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        adTypeId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        adObjectName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        mappid: [{ required: true, message: '不能为空', trigger: 'blur' }],


      },
      temp: {

        id: undefined,
        appId: '',
        mappid:'',
        adObjectName: '',
        adKey: '',
        adSecret: '',

      },
      tableData: [],
      onOffOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      adOptions:[

      ]

    }
  },
  created() {
    this.getList();
    this.getAdsList();
  },
  watch:{
    row: function (n ,o) {
      console.info(o)
      this.temp.appId = n.id;
      this.getList();
    }
  },
  methods:{
    getAdsList(){
      adslistApi().then(response => {
        if(response.body){
          this.adOptions = response.body.records

        }
      })
    },
    getList() {
      this.listQuery.appId = this.row.id;
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {


      this.resetTemp()

      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {

          this.temp.appId = this.row.id;
          console.log(this.temp);
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        appId: this.row.id,
        adObjectName: '',
        adKey: '',
        adSecret: '',
      }
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.temp.adTypeName = this.row.adTypeName
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          tempData.appId = this.row.id;

          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    handleTotalSet(index, row){
      console.log(index, row);
      this.dialogFormTotalConfig = true
      this.pdTotalsetTemp = row;
    },
    handleDetailedSet(index, row){
      console.log(index, row);

    },
    totalConfigVisible(v){
      this.dialogFormTotalConfig = v
    }


  }
}

</script>

<style scoped>


</style>
