import request from '@/utils/request'

export function adslistApi(adid,pd) {
    let data = {current:1,size:50}

    if(adid){
        data.adid = adid
    }
    if(pd) {
        data.pd = pd
    }
    console.info("-----------");
    console.info(data);

    return request({
        url: '/advert/adObjects/list',
        method: 'post',
        data
    })
}
