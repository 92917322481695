import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/appAd/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/appAd/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/appAd/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/appAd/del',
        method: 'post',
        data
    })
}



